<template>
  <v-footer id="dashboard-core-footer" height="50px" elevation="5">
    <div>&copy; {{ $t('branding.copyRight') }}</div>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>
